import baseConfig from '@/config/index'
const AUTH_SERVICE_PATH = baseConfig.AUTH_SERVICE_PATH
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH
export default {
  // Endpoints
  loginEndpoint: TICKET_API_SERVICE_PATH + '/auth/login',
  registerEndpoint: TICKET_API_SERVICE_PATH + '/auth/register',
  forgotPasswordEndpoint: AUTH_SERVICE_PATH + '/auth/forgot-password',
  resetPasswordEndpoint: TICKET_API_SERVICE_PATH + '/auth/reset-password',
  // refreshEndpoint: AUTH_PATH + '/refresh-token',
  logoutEndpoint: AUTH_SERVICE_PATH + '/auth/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
