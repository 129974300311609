export default {
  AUTH_SERVICE_PATH: 'https://gfdjsq5cyd.execute-api.eu-west-1.amazonaws.com/dev',
  TICKET_API_SERVICE_PATH: 'https://ticket-api.numur.mn',
  TEST_SERVICE_PATH: 'https://test-ticketapi.numur.mn',
  // TEST_SERVICE_PATH: 'http://127.0.0.1:5000',
  REPORT_SERVICE_PATH: 'https://d6j8fgoda4.execute-api.eu-west-1.amazonaws.com/dev',
  // FILE_SERVICE_PATH: 'https://fu54rzfxk9.execute-api.eu-west-1.amazonaws.com/dev',
  FILE_SERVICE_PATH: 'https://test-ticketapi.numur.mn',
  BASE_PATH: 'https://wixgpyem7i.execute-api.eu-west-1.amazonaws.com/dev',
  QPAY_SERVICE_PATH: 'https://qpay-api.numur.mn',
  EBARIMT_SERVICE_PATH: 'https://ebarimt.numur.mn'
}